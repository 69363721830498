import { Controller } from '@hotwired/stimulus';
import * as bootstrap from 'bootstrap';

/*
* The following line makes this controller "lazy": it won't be downloaded until needed
* See https://github.com/symfony/stimulus-bridge#lazy-controllers
*/
export default class extends Controller {
    static targets = ['name', 'time', 'content', 'start', 'end', 'notime', 'copystatus', 'copymessage', 'copier']
    static values = {val: String, copyurl: String}

    start;
    duration;

    connect(){
        let start, end, defaultTime;
        if(!this.hasNoTimeTarget && !this.hasStartTarget) return;
        if( this.notimeTarget.checked){
            start = new Date(this.startTarget.value + "T00:00"), end = new Date(this.endTarget.value + "T00:00");
            defaultTime = this.valValue.split(":");
            start.setHours(defaultTime[0]);
            start.setMinutes(defaultTime[1]);
            end.setHours(defaultTime[0]);
            end.setMinutes(defaultTime[1]);
            end.setTime(end.getTime() + 60*60*1000);
        }else{
            start = new Date(this.startTarget.value), end = new Date(this.endTarget.value);
        }
        this.start = start.getTime();
        this.duration = end.getTime() - start.getTime();
    }

    show(e){
        this.nameTarget.innerHTML = e.params.name;
        this.timeTarget.innerHTML = e.params.time;
        this.contentTarget.innerHTML = "";
        fetch(e.params.show).
            then(r=>r.text()).
            then(t=>this.contentTarget.innerHTML = t);
    }

    create(e){
        window.location.href = e.params.newurl;
    }

    copy(e){
        let el = e.target, 
            act = el.form.action,
            toast = bootstrap.Toast.getOrCreateInstance(this.copystatusTarget, {delay: 3000});

        fetch(act,{
            method: "POST",
            credentials: 'same-origin',
            body: new FormData(el.form)
        })
            .then(r=>r.text())
            .then(t=>{
                this.copymessageTarget.innerHTML = t;
                toast.show();
            });
        console.log(act);
    }

    copier(e){
        let modal = new bootstrap.Modal("#copier");
        fetch(this.copyurlValue)
            .then(r=>r.text())
            .then(p=>this.copierTarget.innerHTML = p);

        modal.show();
    }

    notime(e){
        if(e.target.checked == true){
            this.startTarget.type = 'date';
            this.endTarget.type = 'date';
        }else{
            this.startTarget.type = 'datetime-local';
            this.endTarget.type = 'datetime-local';
        }
        this.sync();
    }

    starttime(e){
        let start = new Date(this.startTarget.value);
        this.start = start.getTime();
        this.sync();
    }

    endtime(e){
        let start = new Date(this.startTarget.value), end = new Date(this.endTarget.value);
        if(end < start){
            alert("End time cannot be before start time");
            return;
        }
        this.duration = end.getTime() - start.getTime();
    }

    toISOLocal(adate){
        var localdt = new Date(adate - adate.getTimezoneOffset()*60000);
        return localdt.toISOString().slice(0, -1); 
    }

    sync(){
        let start = new Date(), end = new Date();
        start.setTime(this.start);
        end.setTime(this.start + this.duration);
        if('date' == this.startTarget.type){
            this.startTarget.valueAsDate = start;
            this.endTarget.valueAsDate = end;
        }else{
            this.startTarget.value = this.toISOLocal(start);
            this.endTarget.value = this.toISOLocal(end);
        }
    }
}
