import {Controller} from "@hotwired/stimulus";

export default class extends Controller{
    static classes = ['expand'];
    static outlets = ['expand'];

    expand(){
        if(this.hasExpandClass){
            if(this.hasExpandOutlet){
                this.expandOutlets.forEach(ex=>ex.collapse());
            }
            this.element.classList.add(this.expandClass);
        }
    }

    collapse(){
        if(this.hasExpandClass){
            this.element.classList.remove(this.expandClass);
        }
    }

    toggle(){
        if(this.hasExpandClass){
            if(this.element.classList.contains(this.expandClass)){
                this.collapse();
            }else{
                this.expand();
            }
        }
    }
}
